html,body{
    border:none; padding:0; margin:0;
    background:#FFFFFF;
    color:#202020;
}
body{
    text-align:center;
    font-family:"Roboto",sans-serif;
}

#root{
    height:100%;
    display: flex;
    flex-direction: column;
}

#test{
    margin-top:2em;
}

/* #startStopBtn{
    display:inline-block;
    margin:0 auto;
    color:#6060AA;
    background-color:rgba(0,0,0,0);
    border:0.15em solid #6060FF;
    border-radius:0.3em;
    transition:all 0.3s;
    box-sizing:border-box;
    width:8em; height:3em;
    line-height:2.7em;
    cursor:pointer;
    box-shadow: 0 0 0 rgba(0,0,0,0.1), inset 0 0 0 rgba(0,0,0,0.1);
}
#startStopBtn:hover{
    box-shadow: 0 0 2em rgba(0,0,0,0.1), inset 0 0 1em rgba(0,0,0,0.1);
}
#startStopBtn.running{
    background-color:#FF3030;
    border-color:#FF6060;
    color:#FFFFFF;
}
#startStopBtn:before{
    content:"Start";
}
#startStopBtn.running:before{
    content:"Abort";
} */
#test{
    margin-top:2em;
    margin-bottom:12em;
}
div.testArea{
    display:inline-block;
    width:16em;
    height:12.5em;
    position:relative;
    box-sizing:border-box;
}
div.testArea2{
    display:inline-block;
    width:14em;
    height:7em;
    position:relative;
    box-sizing:border-box;
    text-align:center;
}
div.testArea div.testName{
    position:absolute;
    top:0.1em; left:0;
    width:100%;
    font-size:1.4em;
    z-index:9;
}
div.testArea2 div.testName{
    display:block;
    text-align:center;
    font-size:1.4em;
}
div.testArea div.meterText{
    position:absolute;
    bottom:1.55em; left:0;
    width:100%;
    font-size:2.5em;
    z-index:9;
}
div.testArea2 div.meterText{
    display:inline-block;
    font-size:2.5em;
}
div.meterText:empty:before{
    content:"0.00";
}
div.testArea div.unit{
    position:absolute;
    bottom:2em; left:0;
    width:100%;
    z-index:9;
}
div.testArea2 div.unit{
    display:inline-block;
}
div.testArea canvas{
    position:absolute;
    top:0; left:0; width:100%; height:100%;
    z-index:1;
}
div.testGroup{
    display:block;
    margin: 0 auto;
}
@media all and (max-width:40em){
    body{
        font-size:0.8em;
    }
}

.ui.secondary.pointing.menu .item:focus{
    outline: none;
}

.ui.striped.table tbody tr:nth-child(2n), .ui.striped.table>tr:nth-child(2n){
    background-color: #EBF2FA !important;
}

.valueMbps input{
    width:100px;
}
